type FeatureFlagsType = {
  [key: string]: string | boolean | number | FeatureFlagsType;
};

export const featureFlagNames = {
  homepageOptimisationPhase1: "homepage_optimisation_phase_1",
  homepageResourcePromotion: "homepage_resource_promotion",
  offPlatformResourceButtonLocation: "off_platform_resource_button_location",
  jumbotronPromotions: "jumbotron_promotions",
  jumbotronTopPicksByJobFamily: "jumbotron_top_picks_by_job_family",
  jumbotronWeeklyDeal: "jumbotron_weekly_deal",
  navigationalBlocks: "navigational_blocks",
  budgetReminderBanner: "budget_reminder_banner",
  promoteBookmarks: "promote_bookmarks",
  filtersApplyBudgetButton: "filters_apply_budget_button",
  activityTicker: "activity_ticker",
  labsROIBanner: "labs_roi_banner",
  labsROIFormVariant: "labs_roi_form_variant",
  resourceCardRequestButton: "resource_card_request_button",
  postRequestRecommendations: "post_request_recommendations",
  metabaseAdminDashboards: "metabase_admin_dashboards",
  metabaseTeamDashboards: "metabase_team_dashboards",
  potsLedgerNote: "pots_ledger_note",
  disableRequests: "disable_requests",
};

// remember to remove from stylesheet when deleting
export const variantIds = {
  homepageOptimisationPhase1: {
    familiesList: "homepage-phase-1-job-families--list",
    resourcePromotionPanel: "homepage-phase-1-resourcePromotionPanel",
  },
};

export const setFeatureFlags = (flags: FeatureFlagsType): void =>
  sessionStorage.setItem("featureFlags", JSON.stringify(flags));

export const retrieveFeatureFlags = (): FeatureFlagsType => {
  const flags = sessionStorage.getItem("featureFlags");
  try {
    return flags ? JSON.parse(flags) : {};
  } catch (err) {
    return {};
  }
};
